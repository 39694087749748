<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> UPDATE PRODUCT</v-card-title>
        <form @submit.prevent="CreateData" enctype="multipart/form-data" ref="form">
          <v-row>
            <v-col cols="12" v-if="errors.length">
              <v-alert color="error" v-for="error in errors" :key="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>
                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Permit Stay" v-model="produks.permitstay" dense outlined></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field label="Remark" v-model="produks.remark" dense outlined></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field label="Los" v-model="produks.los" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
             <v-header>Price (Rp)</v-header>
             <currency-input dense outlined v-model="produks.idr" label="Price (Rp)" currency="IDR" locale="id" class="form-control"/></currency-input>
            </v-col>

            <v-col md="6" cols="12">
              <v-header>Price ($)</v-header>
              <currency-input dense outlined v-model="produks.usd" label="Price ($)" currency="USD" locale="id" class="form-control"/></currency-input>
            </v-col>

            <v-col md="6" cols="12">
              <v-header>Active</v-header>
              <v-radio-group v-model="produks.aktif" row>
                <v-radio label="Yes" value="Y" color="info"></v-radio>
                <v-radio label="No" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col md="6" cols="12">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Photo</v-card-text>
              <!-- <v-img :lazy-src="showPhoto()" max-height="150" max-width="150" :src="showPhoto()"></v-img> -->
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper
                    ref="cropper"
                    :key="showPhoto"
                    :aspectRatio="15 / 10"
                    :initialAspectRatio="15 / 10"
                    :src="showPhoto()"
                    preview=".preview"
                  />
                </div>
              </div>

              <v-btn type="submit" color="primary" @click.prevent="showFileChooser" class="me-3 mt-4">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" @click.prevent="cropImage" class="me-3 mt-4"> Crop </v-btn>

              <input style="display: none" ref="input" type="file" accept="image/*" @change="setImage" />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Photo</v-card-text>
              <section class="preview-area">
                <!-- <p>Cover</p> -->
                <div class="cropped-image">
                  <img width="200px" :src="updatePhoto()" />
                  <!-- <img width="200px" :src="clients.photo == '' ? defautlPhoto() : clients.photo" alt="Cropped Image" /> -->
                </div>
              </section>
            </v-col>

            <!-- <v-col md="6" cols="12">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Photo</v-card-text>

              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper ref="cropper" :aspectRatio="15 / 10"
                    :initialAspectRatio="15 / 10" :src="showPhoto()" preview=".preview" />
                </div>
              </div>

              <v-btn type="submit" color="primary" @click.prevent="showFileChooser" class="me-3 mt-4">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" @click.prevent="cropImage" class="me-3 mt-4"> Crop </v-btn>

              <input
                style="display: none"
                ref="input"
                type="file"
                :name="produks.photo_produk"
                accept="image/*"
                @change="setImage"
              />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Photo</v-card-text>
              <section class="preview-area">

                <div class="cropped-image">
                  <img
                    width="200px"
                    :src="produks.photo_produk == '' ? defautlPhoto() : produks.photo_produk"
                    alt="Cropped Image"
                  />
                </div>
              </section>
            </v-col> -->

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'product-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCheckBold, mdiEmailOutline, mdiCellphone, mdiCamera, mdiSlashForward } from '@mdi/js'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Product',
          disabled: false,
          href: '/admin/product',
        },
        {
          text: 'Update Product',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiEmailOutline,
        mdiCellphone,
        mdiCamera,
        mdiSlashForward,
      },
      rules: [value => !value || value.size < 2000000 || 'Photo size should be less than 2 MB!'],

      produks: [],
      errors: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    this.getAllProduk()
    this.showPhoto()
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    getAllProduk() {
      // let uri = `/api/admin/roles/${this.$route.params.id_role}/show`
      let uriClients = process.env.VUE_APP_ROOT_API + `/api/produks/${this.$route.params.id_produk}/show`
      this.axios.get(uriClients).then(response => {
        this.produks = response.data.produk
      })
    },

    defautlPhoto() {
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/no-image.png`
      return imageDefault
    },

    updatePhoto() {
      if (this.produks.photo_produk == null || this.produks.photo_produk == '') {
        // return "../../../../images/guru/no-image.jpg";
        let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/no-image.png`
        return imageDefault
      }

      let img = this.produks.photo_produk

      if (img.length > 100) {
        return this.produks.photo_produk
      } else {
        let ImageProduk =
          process.env.VUE_APP_ROOT_API + `/images/produk/${this.produks.id_produk}/${this.produks.photo_produk}`
        return ImageProduk
      }
    },

    showPhoto() {
      if (typeof this.produks.photo_produk === 'string' && this.produks.photo_produk.length != 0) {
        if (this.produks.photo_produk.length > 100) {
          return this.produks.photo_produk
        }
        let imageProduk =
          process.env.VUE_APP_ROOT_API + `/images/produk/${this.produks.id_produk}/${this.produks.photo_produk}`
        return imageProduk
      }
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/member/no-image.png`
      return imageDefault
    },

    setImage(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.produks.photo_produk = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.produks.photo_produk = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    showFileChooser() {
      this.$refs.input.click()
    },

    CreateData(e) {
      // console.log('PHOTO: ' + this.clients.photo)
      if (this.$data.produks.permitstay.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Permit stay required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.produks.remark.length === 0) {
        // this.errors.push('Email harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Remark required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.produks.los.length === 0) {
        // this.errors.push('Email harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Los required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        // let uri = process.env.VUE_APP_ROOT_API + '/api/produks/store'
        let uri = process.env.VUE_APP_ROOT_API + `/api/produks/${this.$route.params.id_produk}`

        this.axios
          .post(uri, {
            permitstay: this.produks.permitstay,
            remark: this.produks.remark,
            los: this.produks.los,
            idr: this.produks.idr,
            usd: this.produks.usd,
            aktif: this.produks.aktif,
            photo_produk: this.produks.photo_produk,
          })
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Product created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            // this.$router.push({ name: 'users-index' })
            setTimeout(() => {
              this.$router.push({ name: 'product-index' })
            }, 3000)
          })
          .catch(e => {
            this.$swal.fire({
              title: 'Error!',
              html: this.produks.permitstay + ' already used !',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          })

        e.preventDefault()
      }
    },
  },
}
</script>
